import {Exercise, ExerciseTableData} from "../../../../types/domain";
import React, {useEffect, useMemo, useState} from "react";
import {generateExerciseTableData} from "./helperFunctions";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from "mantine-react-table";
import {ActionIcon, Paper} from "@mantine/core";
import {IconSquareRoundedPlus} from "@tabler/icons-react";
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import MethodService from "../../../../services/method.service";
import ExerciseService from "../../../../services/exercise.service";

type ExercisesData = {
    newExercises: Array<Exercise>,
    exercises: Array<Exercise>,
    topic: string
    showErrorModal: (error: string) => void
}

//parent property that will be used in this element
type ExercisesFormProps = ExercisesData & {
    updateFields: (fields: Partial<ExercisesData>) => void
}

export function ExerciseTable({newExercises, exercises, topic, showErrorModal, updateFields}: ExercisesFormProps) {
    const [data, setData] = useState<Array<ExerciseTableData>>(generateExerciseTableData(exercises, [], topic));

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<ExerciseTableData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Übung/Tätigkeit'
            },
            {
                accessorKey: 'description',
                header: 'Beschreibung',
                size: 300
            },
            {
                accessorKey: 'method',
                header: 'Methode',
                size: 50,
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                }

            },
            {
                accessorKey: 'material',
                header: 'Material'
            },
            {
                accessorKey: 'attachments',
                header: 'Anhänge'
            }
        ], []
    );

    /**
     * React to change of new exercises
     */
    useEffect(() => {
        // init table
        let tableData = generateExerciseTableData(exercises, [], topic)
        setData([... tableData]);
    }, [JSON.stringify(exercises)]);

    /**
     * Add exercise to list of new exercises
     * @param name exercise name
     * @param description exercise description
     * @param material exercise material
     * @param method exercise method
     */
    async function addExe(name: string, description: string, material: string, method: string) {
        // add exercise to newExercises

        //check if exercise not already in list
        if (newExercises.some(e => e.name === name
            && e.description === description && e.material === material)){
            showErrorModal("Diese Tätigkeit befindet sich bereits in der Liste!");
        } else {
            await addExeToNewExes(name, description, material, method);
        }
    }

    /**
     * Helper function to add an exercise to the list of new exercises
     * @param name exercise name
     * @param description exercise description
     * @param material exercise material
     * @param method exercise method
     */
    async function addExeToNewExes(name: string, description: string, material: string, method: string){
        const methods = method.split(',')

        let dbMethods = []
        for (const method of methods) {
            const dbMethod = (await MethodService.getByCode(method.trim())).data
            if (dbMethod){
                dbMethods.push(dbMethod)
            }
        }

        if (dbMethods.length !== 0){
            //check if exercise already in database
            let dbExercise = (await ExerciseService.getByNameDescMat(name, description, material)).data;
            if (typeof(dbExercise) === 'string'){   //not in db
                dbExercise = {
                    id: 0,
                    name: name,
                    description: description,
                    material: material,
                    methods: dbMethods,
                    stages: [],
                    planExercises: [],
                    topics: [],
                    attachments: [],
                    user: undefined
                }
            }

            //update list with new exercises
            let adaptNewExe = [... newExercises];
            adaptNewExe.push(dbExercise);

            updateFields({newExercises: adaptNewExe})
        }
    }

    const table = useMantineReactTable<ExerciseTableData>({
        columns,
        data,
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0
            }
        },
        enableColumnActions: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        positionPagination:"bottom",
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
            return <ActionIcon color="blueish" onClick={() => addExe(row.original.name, row.original.description,
                row.original.material, row.original.method)}>
                <IconSquareRoundedPlus/>
            </ActionIcon>
        },
        localization: MRT_Localization_DE,
        mantinePaginationProps: {
            showRowsPerPage: false
        }
    });

    return (
        <>
            <Paper radius="xs" style={{marginTop: "1rem"}}>
                <MantineReactTable
                    table={table}
                />
            </Paper>
        </>
    );
}
