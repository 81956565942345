import {FormWrapper} from "../utils/form/FormWrapper"
import React, {useEffect, useState} from "react";
import {Col, FormGroup, Row} from "react-bootstrap";
import ServiceCenterService from "../../../../services/servicecenter.service";
import {Participant, ServiceCenter, Stage, Traininggroup} from "../../../../types/domain";
import TraininggroupService from "../../../../services/traininggroup.service";
import StageService from "../../../../services/stage.service";
import ParticipantService from "../../../../services/participant.service";
import {Button, Checkbox, Divider, Group, Modal, NumberInput, Radio, Select, TextInput} from "@mantine/core";
import {DateInput, DateValue} from "@mantine/dates";
import {ModalType} from "../../../../types/UITypes";
import {getHolidaysAndSeason} from "../utils/helperFunctions";
import TopicService from "../../../../services/topic.service";
import 'dayjs/locale/de';

/**
 * Form element to set the general information of a new plan
 * @author KMU
 */

type GeneralData = {
    topic: string,
    stages: Array<Stage>,
    serviceCenter: ServiceCenter,
    trainingGroup: Traininggroup | null,
    participant: Participant | null,
    date: string,
    prepTimeContent: number,
    prepTimeOrg: number,
    duration: number,
    timeSupport: number
}

//parent property that will be used in this element
type GeneralFormProps = GeneralData & {
    updateFields: (fields: Partial<GeneralData>) => void,
    updateModal: (modal: ModalType) => void,
    updatePlan: boolean
}

export function GeneralForm({topic, stages, serviceCenter, trainingGroup, participant, date,
                                prepTimeContent, prepTimeOrg, duration, timeSupport,
                                updateFields, updateModal, updatePlan}: GeneralFormProps) {

    const [centers, setCenters] = useState([{label: "", value: ""}]);
    //centers from database
    const [dbCenters, setDBCenters] = useState<ServiceCenter[]>([]);
    //currently selected center
    const [selCenter, setSelCenter] = useState<string | null>(serviceCenter?.name);

    const [selTrainingKind, setSelTrainingKind] = useState<string>(
        trainingGroup !== null ? "GT" : participant !== null ? "ET" : "");
    const [groups, setGroups] = useState([{label: "", value: ""}]);
    //centers from database
    const [dbGroups, setDBGroups] = useState<Traininggroup[]>([]);
    //currently selected group
    const [selGroup, setSelGroup] = useState<string | null>(
        trainingGroup !== null ? trainingGroup?.name : null);

    const [participants, setParticipants] = useState([{label: "", value: ""}]);
    //participants from database
    const [dbParticipants, setDBParticipants] = useState<Participant[]>([]);
    //currently selected group
    const [selParticipant, setSelParticipant] = useState<string | null>(participant !== null ?
        participant.firstname + ";" + participant.lastname : null);

    //stages from the database
    const [dbStages, setStages] = useState<Stage[]>([]);
    const [selStages, setSelStages] = useState<string[]>(stages.map(s => s.name))

    //modal with topic suggestions
    const [modalOpen, setModalOpen] = useState(false);
    const [holidayTopicSuggestions, setHolidayTopicSuggestions] = useState<string[]>([]);
    const [eldestTopicSuggestions, setEldestTopicSuggestions] = useState<string[]>([]);

    //modal with existing topics
    const [topics, setTopics] = useState([{label: "", value: ""}]);
    const [topicModalOpen, setTopicModalOpen] = useState(false);

    /**
     *  Load data before showing the form
     */
    useEffect(() => {
        ServiceCenterService.getAll()
            .then((response: any) => {
                setDBCenters(response.data);
                let centers = []
                for (let i in response.data){
                    centers.push({label: response.data[i].name, value: response.data[i].name})
                }
                setCenters(centers);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        TopicService.getAll()
            .then((response: any) => {
                let topics = []
                for (let i in response.data){
                    if(response.data[i].name !== "ALLGEMEIN"){
                        topics.push({label: response.data[i].name, value: response.data[i].name})
                    }
                }
                setTopics(topics);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        StageService.getAll()
            .then((response: any) => {
                let stages = response.data;
                //move Prophylaxe to first position
                if (stages){
                    const prophStage = stages.pop();
                    stages.unshift(prophStage);
                }
                setStages(stages);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        //servicecenter was already selected
        if (serviceCenter?.id !== 0){
            TraininggroupService.getByServiceCenterName(serviceCenter.name)
                .then((response: any) => {
                    setDBGroups(response.data);
                    let groups = [];
                    for (let i in response.data){
                        groups.push({label: response.data[i].name, value: response.data[i].name})
                    }
                    setGroups(groups);
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            ParticipantService.getByServiceCenterName(serviceCenter.name)
                .then((response: any) => {
                    setDBParticipants(response.data);
                    let participants = [];
                    for (let i in response.data){
                        participants.push({label: response.data[i].firstname + " " + response.data[i].lastname,
                            value: response.data[i].firstname + ";" + response.data[i].lastname})
                    }
                    setParticipants(participants);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [])

    /**
     * Update checkboxes and radio buttons when the user wants to update a plan
     */
    useEffect(() => {
        if (updatePlan){
            setSelParticipant(participant !== null ? participant.firstname + ";" + participant.lastname : null);
            setSelGroup(trainingGroup !== null ? trainingGroup.name : null);
            setSelStages(stages.map(s => s.name));
            setSelTrainingKind(trainingGroup !== null ? "GT" : participant !== null ? "ET" : "");
            setSelCenter(serviceCenter.name);
            setTrainingGroupsByCenter(serviceCenter.name);
            setParticipantsByCenter(serviceCenter.name);
        }
    }, [updatePlan]);

    /**
     * Change event of date value
     * @param newValue new date value
     */
    function dateChange(newValue: DateValue) {
        if (newValue) {
            const dateObj = new Date(newValue);

            const day = String(dateObj.getDate()).padStart(2, '0'); // Day with leading zero
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so +1
            const year = dateObj.getFullYear();

            // Custom format: DD-MM-YYYY
            const formattedDate = `${year}-${month}-${day}`;

            updateFields({date: formattedDate})
        }
    }

    /**
     * Change event of the servicecenter dropdown
     * Changing the servicecenter leads to a load of the
     * groups and participants
     * @param newValue name of the selected center
     */
    function serviceCenterChangeEvent(newValue: string){
        setSelCenter(newValue);
        if (newValue){
            const center = dbCenters.find((center) => center.name === newValue);
            updateFields({serviceCenter: center});

            setTrainingGroupsByCenter(newValue);
            setParticipantsByCenter(newValue);
            setSelGroup(null);
            setSelParticipant(null);
            updateFields({trainingGroup: null, participant: null});

        }
    }

    /**
     * Set the array with the trainings groups of the center with the
     * given name
     * @param centerName name of the center
     */
    function setTrainingGroupsByCenter(centerName: string) {
        TraininggroupService.getByServiceCenterName(centerName)
            .then((response: any) => {
                setDBGroups(response.data);
                let groups = [];
                for (let i in response.data){
                    groups.push({label: response.data[i].name, value: response.data[i].name})
                }
                setGroups(groups);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    /**
     * Set the array with the participants of the center with the
     * given name
     * @param centerName name of the center
     */
    function setParticipantsByCenter(centerName: string) {
        ParticipantService.getByServiceCenterName(centerName)
            .then((response: any) => {
                setDBParticipants(response.data);
                let participants = [];
                for (let i in response.data){
                    participants.push({label: response.data[i].firstname + " " + response.data[i].lastname,
                        value: response.data[i].firstname + ";" + response.data[i].lastname})
                }
                setParticipants(participants);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }


    /**
     * Change event of the groups dropdown
     * @param newValue name of the selected training group
     */
    function groupChangeEvent(newValue: string){
        setSelGroup(newValue);
        const group = dbGroups.find((group) => group.name === newValue);

        if(group){
            let adaptedSelValues: string[] = [];
            stages = []

            group.participants.forEach(participant => {
                const stage = dbStages.find((stage) => stage.name === participant.stage?.name)
                if (stage) {
                    stages.push(stage)
                }
                adaptedSelValues.push(stage!.name);
            })

            updateFields({stages: stages});
            setSelStages(adaptedSelValues);
        }

        updateFields({trainingGroup: group});
    }

    /**
     * Change event of the participants dropdown
     * @param newValue name of the selected participant
     */
    function participantChangeEvent(newValue: string) {
        const names = newValue.split(';')
        const participant = dbParticipants.find((p) => p.firstname === names[0]
            && p.lastname === names[1]);

        if(participant){
            let adaptedSelValues: string[] = [];
            stages = []
            const stage = dbStages.find((stage) => stage.name === participant.stage?.name)
            if (stage) {
                stages.push(stage)
            }
            adaptedSelValues.push(stage!.name);

            updateFields({stages: stages});
            setSelStages(adaptedSelValues);
        }

        updateFields({participant: participant})
        setSelParticipant(newValue);
    }

    /**
     * Change event of the stages checkboxes
     * @param selValues array of selected stages
     */
    function stageChangeEvent(selValues: string[]){
        let adaptedSelValues: string[] = [];

        //if Prophylaxe is selected only 1, 2 and 3 will be selected
        stages = []
        selValues.forEach(val => {
            if (!selValues.includes("Prophylaxe") || (selValues.includes("Prophylaxe") &&
                ["Prophylaxe", "1", "2", "3"].includes(val))){
                const stage = dbStages.find((stage) => stage.name === val)
                if (stage) {
                    stages.push(stage)
                }
                adaptedSelValues.push(val);
            }
        });

        updateFields({stages: stages});
        setSelStages(adaptedSelValues);
    }

    /**
     * Change event of the training kind checkboxes
     * @param selValue selected training kind
     */
    function trainingKindChangeEvent(selValue: string) {
        setSelTrainingKind(selValue);
        if (selValue === "GT") {
            updateFields({participant: null, trainingGroup: {id: 0, name: "", participants: [], serviceCenter: undefined}})
        } else {
            updateFields({trainingGroup: null, participant: {id:0, firstname:"", lastname:"", serviceCenter: undefined, stage: undefined}})
        }
    }

    /**
     * Suggest potential topics by checking if there is a holiday near
     * and getting 5 topics that were not used for the longest
     */
    async function suggestTopic() {
        if (date === ""){
            updateModal({
                show: true,
                title: "Fehlermeldung",
                body: "Es muss zuerst ein Datum gewählt werden!",
                decline: false,
                handleOk: () => updateModal({
                    show: false,
                    title: "",
                    body: "",
                    decline: false,
                    handleOk: () => {},
                    handleCancel: () => {}
                }),
                handleCancel: () => {}
            })
        } else {
            setHolidayTopicSuggestions(getHolidaysAndSeason(date));

            let suggestions: string[] = [];
            await TopicService.getNEldest(5).then((response: any) => {
                for (let i in response.data){
                    suggestions.push(response.data[i].name);
                }
            }).catch((e: Error) => {
                console.log(e);
            });

            setEldestTopicSuggestions(suggestions);
            setModalOpen(true);
        }
    }

    function closeModal() {
        setModalOpen(false)
    }

    function topicChange(selValue: string) {
        updateFields({topic: selValue});
        closeModal();
    }

    return (
        <FormWrapper title="Allgemeine Infos">
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Select
                            data={centers}
                            value={selCenter}
                            placeholder="Wähle eine Servicestelle aus"
                            onChange={serviceCenterChangeEvent}
                            withAsterisk
                            label="Demenzservicestelle"
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="newExerciseField">
                        <DateInput
                            id="date"
                            value={date !== "" ? new Date(date) : null}
                            required
                            onChange={newValue => dateChange(newValue)}
                            locale="de"
                            label="Datum"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Radio.Group
                            name="trainigKindGroup"
                            onChange={trainingKindChangeEvent}
                            value={selTrainingKind}
                            label="Trainingsart"
                            withAsterisk
                        >
                            <Group>
                                <Radio
                                    id="trainingKindBox-ET"
                                    label="Einzeltraining"
                                    value="ET"
                                    checked={participant !== null} />
                                <Radio
                                    id="trainingKindBox-GT"
                                    label="Gruppentraining"
                                    type="radio"
                                    value="GT"
                                    checked={trainingGroup !== null}/>
                            </Group>
                        </Radio.Group>
                    </FormGroup>
                </Col>
                <Col>
                    {
                        selTrainingKind === "GT" ?
                            <FormGroup className="newExerciseField">
                                <Select
                                    data = {groups}
                                    value={selGroup}
                                    onChange={groupChangeEvent}
                                    placeholder= "Wähle eine Gruppe aus"
                                    label="Gruppe"
                                    withAsterisk
                                ></Select>
                            </FormGroup> : <></>
                    }
                    {
                        selTrainingKind === "ET" ?
                            <FormGroup className="newExerciseField">
                                <Select
                                    data = {participants}
                                    value={selParticipant}
                                    onChange={participantChangeEvent}
                                    placeholder= "Wähle eine/n Teilnehmer*in aus"
                                    label="Teilnehmer*in"
                                    withAsterisk
                                ></Select>
                            </FormGroup> : <></>
                    }
                </Col>
            </Row>
            <Row>
                <FormGroup className="newExerciseField">
                    <>
                        <Checkbox.Group
                            value={selStages}
                            onChange={stageChangeEvent}
                            label="Stadium"
                            withAsterisk
                        >
                            <Group>
                            {
                                dbStages.map(stage =>
                                    <Checkbox
                                        label={stage.name}
                                        value={stage.name}
                                        disabled={selStages.includes("Prophylaxe") &&
                                            !["Prophylaxe", "1", "2", "3"].includes(stage.name)}
                                    />
                                )
                            }
                            </Group>
                        </Checkbox.Group>
                    </>
                </FormGroup>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={trainingGroup !== null ? 2.5 : 2}
                            precision={2}
                            decimalSeparator=','
                            id="duration"
                            value={duration}
                            defaultValue={duration}
                            onChange={val => updateFields({duration: val === '' ? 0 : val})}
                            label="Trainingsdauer [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={trainingGroup !== null ? duration * 0.5 : 0.5}
                            precision={2}
                            decimalSeparator=','
                            id="prepTimeContent"
                            value={prepTimeContent}
                            defaultValue={prepTimeContent}
                            onChange={val => updateFields({prepTimeContent: val === '' ? 0 : val})}
                            label="Vorbereitungszeit (Inhalt) [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={0.5}
                            precision={2}
                            decimalSeparator=','
                            id="prepTimeOrg"
                            value={prepTimeOrg}
                            defaultValue={prepTimeOrg}
                            onChange={val => updateFields({prepTimeOrg: val === '' ? 0 : val})}
                            label="Vorbereitungszeit (Org.) [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
                <Col>
                    {trainingGroup !== null ?
                        <FormGroup className="newExerciseField">
                            <NumberInput
                                min={0}
                                step={0.25}
                                max={0.5}
                                precision={2}
                                decimalSeparator=','
                                id="timeSupport"
                                value={timeSupport}
                                defaultValue={timeSupport}
                                onChange={val => updateFields({timeSupport: val === '' ? 0 : val})}
                                label="Zeit für Praktikumsbegleitung [Std.]"
                                withAsterisk
                            />
                        </FormGroup>
                        : <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <TextInput
                            id="topic"
                            required
                            value={topic}
                            onChange={e => updateFields({topic: e.target.value.toUpperCase()})}
                            label="Thema"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
                <Col className="newExerciseField" md="auto" style={{display: "flex", alignItems: "flex-end", padding: 0}}>
                    <Button onClick={() => setTopicModalOpen(true)}>Thema auswählen</Button>
                </Col>
                <Col className="newExerciseField" style={{display: "flex", alignItems: "flex-end"}}>
                    <Button onClick={suggestTopic}>Thema vorschlagen lassen</Button>
                </Col>
            </Row>
            <Modal opened={modalOpen} onClose={closeModal} title="Themenvorschläge">
                <Divider my="xs" label="Feiertage und Jahreszeit" style={{marginTop: 0}}/>
                <Radio.Group onChange={topicChange}>
                    <Group mt="xs">
                        {
                            holidayTopicSuggestions.map(ts =>
                                <Radio value={ts} label={ts} />
                            )
                        }
                    </Group>
                </Radio.Group>
                <Divider my="xs" label="Länger nicht durchgeführt"/>
                <Radio.Group onChange={topicChange}>
                    <Group mt="xs">
                        {
                            eldestTopicSuggestions.map(ts =>
                                <Radio value={ts} label={ts} />
                            )
                        }
                    </Group>
                </Radio.Group>
            </Modal>
            <Modal opened={topicModalOpen} onClose={() => setTopicModalOpen(false)} title="Vorhandene Themen"
                   className="topicSelectModal">
                <Select
                    id="topic"
                    data={topics}
                    required
                    onChange={value => {
                        updateFields({topic: value ? value.toUpperCase() : ""})
                        setTopicModalOpen(false)
                    }}
                    searchable
                    label="Ein vorhandenes Thema auswählen"
                    withAsterisk
                    dropdownPosition="bottom"
                />
            </Modal>
        </FormWrapper>
    )
}