import Logo from "../header/Logo";
import LogoFooter from "../footer/LogoFooter";
import "../../css/index.css"
import React from "react";
import Navigation from "../header/Navigation";


export default function Impressum() {
    const height = 200;
    return (
        <div className="impressum">
            <div className="header">
                <Logo/>
                <Navigation/>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-mannrechts.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{padding: "2rem 4rem", display: "flex", flexDirection: "column"}}>
                <h1><b>Impressum</b></h1>
                <div className="text-block">
                    <p>Copyright © 2024 Research Group Bioinformatics Hagenberg <br/>
                        This copyright notice is based on a Freenetlaw template supplied by Contractology.</p>
                    <p>The copyright in this website and the material on this website (including without limitation the
                        text,
                        computer code, artwork, photographs, images, music, audio material, video material and
                        audio-visual
                        material on this website) is owned by Research Group Bioinformatics Hagenberg [and its
                        licensors]</p>
                </div>

                <div className="text-block">
                    <h5>COPYRIGHT LICENSE</h5>
                    <p>
                        Research Group Bioinformatics Hagenberg grants to you a worldwide non-exclusive royalty-free
                        revocable license to:
                        <ul>
                            <li>view this website and the material on this website on a computer or mobile device via a
                                web browser
                            </li>
                            <li>copy and store this website and the material on this website in your web browser cache
                                memory
                            </li>
                            <li>print pages from this website for your own [personal and non-commercial] use.</li>
                        </ul>
                        Research Group Bioinformatics Hagenberg does not grant you any other rights in relation to this
                        website or the material
                        on this website. In other words, all other rights are reserved. For the avoidance of doubt, you
                        must not adapt, edit,
                        change, transform, publish, republish, distribute, redistribute, broadcast, rebroadcast or show
                        or play in public this
                        website or the material on this website (in any form or media) without Research Group
                        Bioinformatics Hagenberg’s prior written permission.
                    </p>

                    <h5>LIABILITY</h5>
                    <p>Numerous texts found on the homepage of Research Group Bioinformatics Hagenberg have been
                        carefully checked.
                        We cannot however verify the accuracy, completeness and extent to which information is up to
                        date.
                        The Research Group Bioinformatics Hagenberg cannot therefore be held responsible for text
                        content.
                        Links to other websites have been carefully selected.
                    </p>

                    <h5>DATA MINING</h5>
                    <p>The automated and/or systematic collection of data from this website is prohibited.</p>

                    <h5>PERMISSIONS</h5>
                    <p>You may request permission to use the copyright materials on this website by writing an
                        e-mail.</p>

                    <h5>IMAGE CREDITS</h5>
                    <p>The images used on this website are partly sourced from Pixabay.com or created using AI-based
                        image generation by OpenAI (DALL·E).</p>
                </div>
            </div>
            <LogoFooter/>
        </div>
    );
};