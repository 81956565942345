import http from "./http-common"
import {Exercise, Topic} from "../types/domain";

/**
 * Service class for the Topic type
 * @author KMU
 */
class TopicService {

    url = `/topics/`;

    /**
     * Get Topic by name
     * @param name name of the Topic
     * @return Topic entity
     */
    getByName(name: string) {
        return http.get<Topic>(this.url + `getByName/${name}`);
    }

    create(data: Topic) {
        return http.post<Topic>(this.url + `create`, data);
    }

    getNEldest(n: number){
        return http.get<Topic[]>(this.url + `getNEldest/${n}`);
    }

    getExercises(id: number){
        return http.get<Exercise[]>(this.url + `getExercises/${id}`);
    }

    getAll() {
        return http.get<Array<Topic>>(this.url + `getAll`);
    }
}

export default new TopicService();