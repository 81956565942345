import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import "./css/views/header/Header.css"
import {Container} from "react-bootstrap";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {HashRouter} from "react-router-dom";
import App from "./App";

/**
 * Main file
 */
ReactDOM.render(
    <HashRouter>
        <Container id="mainContainer" style={{padding: 0, margin: 0, }}>
            <App/>
        </Container>
    </HashRouter>,
document.getElementById('root')
);