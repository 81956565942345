import "../../css/views/header/Logo.css"

export default function Logo(){

    return(
        <div id="logoContainer">
            <div id="bigLogo">
                <img id="logo" src={require('./../../assets/logo/Tybo-Gehirn.png')} alt="TYBO-logo"/>
                <img id="text" src={require("../../assets/logo/Tybo-schrift.png")} alt="TYBO-text"/>
            </div>
            <div id="smallLogo">
                <img id="logo" src={require('./../../assets/logo/Tybo-quer.png')} alt="TYBO-logo"/>
            </div>
        </div>
    );
}