export default  function SocialMedia(){
    const height = 50;

    return(
        <div id="socialmedia-div" style={{display: "flex", alignItems: "center"}}>
            <a href="https://www.facebook.com/p/MAS-Alzheimerhilfe-100064848901839/" target="_blank">
                <img src={require('./../../assets/icons/facebook.png')} alt="facebook-icon"
                     height={height}/>
            </a>
            <a href="https://www.instagram.com/mas_alzheimerhilfe/?hl=de" target="_blank">
                <img src={require("../../assets/icons/insta.png")} alt="instagram-icon"
                     height={height}/>
            </a>
            <a href="https://www.youtube.com/@masalzheimerhilfe3002/featured" target="_blank">
                <img src={require("../../assets/icons/youtube.png")} alt="youtube-icon"
                     height={height}/>
            </a>
        </div>
    );
}