import {FormWrapper} from "../utils/form/FormWrapper"
import {
    Exercise,
    ExerciseAttachments,
    ExerciseTableData,
    PlanExercise,
    PlanExerciseTableData,
    Topic,
    Useraccount
} from "../../../../types/domain";
import React, {useEffect, useMemo, useState} from "react";
import {ExerciseCreatePanel} from "../utils/ExerciseCreatePanel";
import {generatePlanExerciseTableData, sortPlanExercisesByPosition} from "../utils/helperFunctions";
import {MantineReactTable, MRT_ColumnDef, MRT_Row, useMantineReactTable} from "mantine-react-table";
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {Select} from "@mantine/core";
import TopicService from "../../../../services/topic.service";
import UseraccountService from "../../../../services/useraccount.service";
import ExerciseService from "../../../../services/exercise.service";

/**
 * @author KMU
 * Form element to create the new exercises
 */

type ExercisesData = {
    planExercises: Array<PlanExercise> | null
    newExercises: Array<Exercise>,
    newAttachments: Array<ExerciseAttachments>
    topic: Topic
    user: Useraccount
}

//parent property that will be used in this element
type ExercisesFormProps = ExercisesData & {
    updateFields: (fields: Partial<ExercisesData>) => void
}
export function ExercisesForm({planExercises, newExercises, newAttachments, topic, user,
                                updateFields}: ExercisesFormProps) {

    const [data, setData] = useState<Array<PlanExerciseTableData>>(generatePlanExerciseTableData(planExercises, newAttachments, topic.name));
    const [allExercises, setAllExercises] = useState<Array<Exercise>>([]);
    const [topicExercises, setTopicExercises] = useState<Array<Exercise>>([]);
    const [userExercises, setUserExercises] = useState<Array<Exercise>>([]);


    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<PlanExerciseTableData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Übung/Tätigkeit'
            },
            {
                accessorKey: 'description',
                header: 'Beschreibung',
                size: 300
            },
            {
                accessorKey: 'method',
                header: 'Methode',
                size: 50,
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                }

            },
            {
                accessorKey: 'material',
                header: 'Material'
            },
            {
                accessorKey: 'attachments',
                header: 'Anhänge'
            },
            {
                accessorKey: 'execution',
                header: 'Durchführung',
                Cell: ({ cell, row}) => (
                    <Select
                        data={['Ja', 'Nein']}
                        name="execution-dropdown"
                        value={cell.getValue<string>()}
                        onChange={(selectedValue) => changeExecution(selectedValue!, row.original)}
                        withinPortal
                    />
                ),
            },
            {
                accessorKey: 'interest',
                header: 'Interesse',
                editVariant: 'select',
                Cell: ({ cell, row}) => (
                    <Select
                        data={['hoch', 'mittel', 'gering']}
                        name="interest-dropdown"
                        value={cell.getValue<string>()}
                        onChange={(selectedValue) => changeInterest(selectedValue!, row.original)}
                        withinPortal
                        disabled={row.original.execution === 'Nein'}
                    />
                ),

            }
        ], [planExercises]
    );

    /**
     * Called in the beginning and when dependency changes
     */
    useEffect(() => {
        //get exercises of topic
        TopicService.getExercises(topic.id).then((response: any) => {
            setTopicExercises(response.data);
        }).catch((e: Error) => {
            console.log(e);
        });

        //get user specific exercises
        UseraccountService.getExercises(user.id).then((response: any) => {
            setUserExercises(response.data);
        }).catch((e: Error) => {
            console.log(e);
        });

        //get all exercises
        ExerciseService.getAll().then((response: any) => {
            setAllExercises(response.data);
        }).catch((e: Error) => {
            console.log(e);
        });

        //init with already planed ones
        if (newExercises.length === 0){
            let newExes: Array<Exercise> = [];
            planExercises?.forEach((planExercise) => {
                planExercise.execution = 'Ja';
                newExes.push(planExercise.exercise)
            })
            updateFields({newExercises: newExes, planExercises: planExercises});
        } else {
            if (planExercises){
                let pos = planExercises.length  + 1
                let newPlanExes = [...planExercises];
                newExercises.forEach((exercise) => {
                    //save only the new entry
                    if (!newPlanExes.some(e => e.exercise.name === exercise.name
                        && e.exercise.description === exercise.description && e.exercise.material === exercise.material)){
                        newPlanExes.push({
                            id: 0,
                            exercise: exercise,
                            execution: 'Ja',
                            interest: undefined,
                            plan: undefined,
                            position: pos
                        })
                        pos++;
                    }
                })
                newPlanExes = sortPlanExercisesByPosition(newPlanExes);
                updateFields({planExercises: newPlanExes});
                setData([... generatePlanExerciseTableData(newPlanExes, newAttachments, topic.name)]);
            }
        }
    }, [JSON.stringify(newExercises)]);

    /**
     * Change event of the execution dropdown
     * @param value selected value
     * @param data row data
     */
    function changeExecution(value: string, data: any) {
        if (planExercises){
            let adaptedPlanExes = [...planExercises];
            let index = adaptedPlanExes.findIndex(e =>
                                                            e.exercise.name === data.name &&
                                                            e.exercise.description === data.description &&
                                                            e.exercise.material === data.material);
            adaptedPlanExes[index].execution = value;

            adaptedPlanExes = sortPlanExercisesByPosition(adaptedPlanExes);
            updateFields({planExercises: adaptedPlanExes});

            setData([... generatePlanExerciseTableData(adaptedPlanExes, newAttachments, topic.name)]);
        }
    }

    /**
     * Change event of the interest dropdown
     * @param value selected value
     * @param data row data
     */
    function changeInterest(value: string, data: any) {
        if (planExercises){
            let adaptedPlanExes = [...planExercises];
            let index = adaptedPlanExes.findIndex(e =>
                                                            e.exercise.name === data.name &&
                                                            e.exercise.description === data.description &&
                                                            e.exercise.material === data.material);

            adaptedPlanExes[index].interest = value === "hoch" ? 3 : value === "mittel" ? 2 : 1;

            adaptedPlanExes = sortPlanExercisesByPosition(adaptedPlanExes);
            updateFields({planExercises: adaptedPlanExes});

            setData([... generatePlanExerciseTableData(adaptedPlanExes, newAttachments, topic.name)]);
        }
    }

    /**
     * Reorder exercises according to table data
     * @param tableData exercises in table data form
     */
    function reOrderPlanExercises(tableData: Array<ExerciseTableData>){
        if (planExercises){
            let reorderedExes:Array<PlanExercise> = []
            let pos = 1;
            tableData.forEach(row => {
                const planExe = planExercises.find(e => e.exercise.name === row.name
                    && e.exercise.description === row.description && e.exercise.material === row.material)
                if (planExe){
                    planExe.position = pos;
                    reorderedExes.push(planExe);
                    pos++;
                }
            })

            reorderedExes = sortPlanExercisesByPosition(reorderedExes);

            updateFields({planExercises: reorderedExes});
            setData([... generatePlanExerciseTableData(reorderedExes, newAttachments, topic.name)]);
        }
    }

    const table = useMantineReactTable<PlanExerciseTableData>({
        columns,
        data,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableSorting: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        localization: MRT_Localization_DE,
        enableRowOrdering: true,
        mantineRowDragHandleProps: {
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        (hoveredRow as MRT_Row<PlanExerciseTableData>).index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                    );
                    reOrderPlanExercises(data);
                }
            }
        },
        enableStickyHeader: true,
        mantineTableContainerProps: { sx: { maxHeight: '500px' }},
        enablePagination: false
    });

    return (
        <>
            <ExerciseCreatePanel newExercises={newExercises} allExercises={allExercises} topicExercises={topicExercises} userExercises={userExercises}
                                 newAttachments={newAttachments} topic={topic.name} updateFields={updateFields}/>
            <hr></hr>
            <FormWrapper title="">
                <div className="exerciseListTable">
                    <h4 className="heading">Durchgeführte Tätigkeiten</h4>
                    <MantineReactTable
                        table={table}
                    />
                </div>
            </FormWrapper>
        </>
    )
}