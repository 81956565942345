import React, {FormEvent, useEffect, useState} from "react";
import '../../css/components/NewPlan.css';
import {useMultistepForm} from "./components/utils/form/useMultistepForm";
import {
    Attachment,
    GroupTrainingAttendance,
    Note,
    Participant,
    ParticipantNoteContent,
    Plan, PlanExercise,
    ReworkPlanFormData
} from "../../types/domain";
import {useLocation, useNavigate} from 'react-router-dom';
import CustomModal from "./components/utils/modal/CustomModal";
import {GeneralForm} from "./components/reworkPlanSteps/GeneralForm";
import {ExercisesForm} from "./components/reworkPlanSteps/ExercisesForm";
import StateService from "../../services/state.service";
import GroupTrainingAttendanceService from "../../services/grouptrainingattendance.service";
import PlanService from "../../services/plan.service";
import {Button} from "@mantine/core";
import AppHeader from "../header/AppHeader";
import {Container} from "react-bootstrap";
import {NotesForm} from "./components/reworkPlanSteps/NotesForm";
import Cookies from "js-cookie";
import {adaptFileName, standardizeTopicName} from "./components/utils/helperFunctions";
import FtpService from "../../services/ftp.service";
import TopicService from "../../services/topic.service";

/**
 * Rework plan page
 * @author KMU
 */

const INITIAL_DATA: ReworkPlanFormData = {
    topic: {
        id: 0,
        name: ""
    },
    stages: [],
    serviceCenter: {
        id: 0,
        name: ""
    },
    trainingGroup: null,
    participant: null,
    date: "",
    prepTimeContent: 0,
    prepTimeOrg: 0,
    duration: 0,
    timeSupport: 0,
    planExercises: [],
    newExercises: [],
    newAttachments: [],
    trainingAttendances: [],
    planNoteContent: "",
    groupNoteContent: "",
    participantNoteContents: [],
    notes: [],
    user: {
        id: 0,
        username: ""
    },
    creationDate: ""
}

export default function ReworkPlan() {
    const navigate = useNavigate();

    //state that contains the id of the plan that will be reworked
    const state = useLocation();

    //form data mapped to a reworked plan
    const [data, setData] = useState(INITIAL_DATA)
    const [modal, setModal] = useState({
        show: false,
        title: "",
        body: "",
        decline: false,
        handleOk: (() => {}),
        handleCancel: (() => {})
    })

    //plan that will be reworked
    const [plan, setPlan] = useState<Plan> ({
        id: 0,
        date: data.date,
        duration: data.duration,
        prepTimeContent: data.prepTimeContent,
        prepTimeOrg: data.prepTimeOrg,
        timeSupport: data.timeSupport,
        exercises: null,
        stages: data.stages,
        state: {id: 0, name: ""},
        topic: {id: 0, name: ""},
        group: data.trainingGroup,
        participant: null,
        notes: data.notes,
        user: data.user!,
        creationDate: data.creationDate
    });

    const [isSaving, setIsSaving] = useState(false);



    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

    /**
     * Helper function to update the data fields
     * @param fields fields with new values
     */
    function updateFields(fields: Partial<any>) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    //attribute to navigate between the form pages
    const {step,
        isFirstStep,
        isLastStep, back, next } =
        useMultistepForm([
            <GeneralForm {...data} updateFields={updateFields} />,
            <ExercisesForm {...data} updateFields={updateFields} />,
            <NotesForm {...data} updateFields={updateFields} />,
        ])

    //get data for plan with the given id from the database
    useEffect(() => {
        const dbPlan = state.state;
        if (dbPlan != null){
            setPlan(dbPlan);

            //init group training attendances entries
            let attendances: Array<GroupTrainingAttendance> = [];
            let participantNotes: Array<ParticipantNoteContent> = [];
            let groupNoteContent: string | null =  null;    //only when group training needed

            if (dbPlan.group != null){
                data.serviceCenter = dbPlan.group.serviceCenter
                groupNoteContent = "";

                dbPlan.group.participants.forEach((participant: Participant) => {
                    attendances.push({
                        id: 0,
                        plan: dbPlan,
                        group: dbPlan.group,
                        participant: participant,
                        attended: false
                    })

                    participantNotes.push({
                        participant: participant,
                        content: ""
                    })
                })
            } else if (dbPlan.participant != null){
                data.serviceCenter = dbPlan.participant.serviceCenter
                participantNotes.push({
                    participant: dbPlan.participant,
                    content: ""
                })
            }

            setData({...data, topic: dbPlan.topic,
                stages: dbPlan.stages, trainingGroup: dbPlan.group, participant: dbPlan.participant,
                date: dbPlan.date, prepTimeOrg: dbPlan.prepTimeOrg,
                prepTimeContent: dbPlan.prepTimeContent, timeSupport: dbPlan.timeSupport,
                duration: dbPlan.duration, planExercises: dbPlan.exercises.sort((a:PlanExercise, b:PlanExercise) => a.position! - b.position!),
                trainingAttendances: attendances, groupNoteContent: groupNoteContent,
                participantNoteContents: participantNotes, user: dbPlan.user
            })
        }

        // Reset newExercises list for exercises form
        updateFields({newExercises: [], newAttachments: []})
    }, [])

    /**
     * Handler function to navigate back to the plan overview page
     */
    const handleBackToOverview = () =>  setModal({...modal,
        show: true,
        title: "Hinweis",
        body: "Achtung, jegliche nicht gespeicherten Eingaben gehen verloren!",
        decline: true,
        handleOk: () => {
            setModal({...modal, show: false});

            //reset data
            setData(INITIAL_DATA);
            updateFields({newExercises: [], newAttachments: []})
            const dbPlan = state.state;
            updateFields({planExercises: dbPlan.exercises})

            navigate('/plans', {replace: true})
        },
        handleCancel: () => setModal({...modal, show: false})
    });

    /**
     * Save reworked plan
     * @param e form event
     */
    async function onSubmit(e: FormEvent) {
        e.preventDefault()

        if (isFirstStep){
            if (data.duration == 0){
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Die Trainingsdauer kann nicht 0 sein!",
                    decline: false,
                    handleOk: () => {
                        setModal({...modal, show: false});
                    },
                    handleCancel: () => {}
                })
            } else {
                return next();
            }
        } else if (!isLastStep) { //step with exercise list
            //check if all exercises that were executed also have an interest filled in
            let missingRework = false;
            for (let i in data.planExercises){
                let planExe = data.planExercises[parseInt(i)];
                missingRework = planExe.execution === null || planExe.execution === undefined ||
                    (planExe.execution === "Ja" && (planExe.interest === null || planExe.interest === undefined))
                if (missingRework){
                    break;
                }
            }

            //only if all exercises were reworked the plan can be updated
            if (missingRework){
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es müssen alle Übungen nachbearbeitet werden!",
                    decline: false,
                    handleOk: () => {
                        setModal({...modal, show: false});
                    },
                    handleCancel: () => {}
                })
            } else {
                return next();
            }
        } else {
            setIsSaving(true);

            //create attachments
            //timestamp for filename adaption
            const t = new Date();
            const timestamp = `${t.getDate().toString().padStart(2,'0')}${(t.getMonth()+1).toString().padStart(2,'0')}${t.getFullYear()%100}_${t.getHours().toString().padStart(2,'0')}${t.getMinutes().toString().padStart(2,'0')}${t.getSeconds().toString().padStart(2,'0')}`;

            for (const exeAttachment of data.newAttachments) {
                const exe = exeAttachment.exercise; //attachment exercise
                const newExe = data.planExercises!.find(e => e.exercise.name === exe.name
                    && e.exercise.description === exe.description && e.exercise.material === exe.material); //exercise in list of new exercises

                //find any attachment that is marked as 'Lösungsblatt'
                const solution = exeAttachment.attachments.find(a => a.type.name === "Lösungsblatt");

                let newExeAttachments:Array<Attachment> = [];
                for (const a of exeAttachment.attachments) {
                    let attachmentTopic = plan.topic;
                    if (!a.isTopicSpecific){
                        attachmentTopic = (await TopicService.getByName("ALLGEMEIN")).data; //attachment is not topic specific
                    }

                    a.topic = attachmentTopic.name;

                    if (a.type.name !== 'Lösungsblatt'){    //solutions are not saved as separate attachments
                        newExeAttachments.push({
                            id: 0,
                            attachmentType: a.type,
                            path: standardizeTopicName(attachmentTopic.name) + "/" + adaptFileName(a.name, timestamp),
                            solutionPath: a.type.name === 'Arbeitsblatt' && solution ? standardizeTopicName(attachmentTopic.name) + "/" + adaptFileName(solution.name, timestamp) : "",
                            stages: data.stages,
                            topic: attachmentTopic
                        });
                    }
                }

                newExe?.exercise.attachments.push(... newExeAttachments);
            }

            //set remaining attributes
            plan.state = (await StateService.getByName("abgeschlossen")).data;
            plan.exercises = data.planExercises;
            plan.duration = data.duration;
            plan.prepTimeContent = data.prepTimeContent;
            plan.prepTimeOrg = data.prepTimeOrg;
            plan.timeSupport = data.timeSupport;

            data.trainingAttendances.forEach(attendance => {
                GroupTrainingAttendanceService.create(attendance);
            });

            //set user of exercises, where user is not set yet
            data.newExercises.forEach(exercise => {
                if (!exercise.user){
                    exercise.user = plan.user;
                }
            });

            //create notes
            let notes: Array<Note> = [];
            if (data.planNoteContent.trim() !== ""){
                notes.push({
                    id: 0,
                    plan: plan,
                    group: null,
                    participant: null,
                    content: data.planNoteContent
                })
            }

            if (data.groupNoteContent && data.groupNoteContent.trim() !== ""){
                notes.push({
                    id: 0,
                    plan: plan,
                    group: plan.group,
                    participant: null,
                    content: data.groupNoteContent
                })
            }

            data.participantNoteContents.forEach(note => {
                if (note.content.trim() !== ""){
                    notes.push({
                        id: 0,
                        plan: plan,
                        group: null,
                        participant: note.participant,
                        content: note.content
                    })
                }
            });

            //update plan
            await PlanService.updateWithNotes(plan, notes, plan.id);

            //save files of attachments on ftp server
            for (const exeAttachment of data.newAttachments) {
                for (const a of exeAttachment.attachments) {
                    const adaptedFile = new File([a.file!], adaptFileName(a.file!.name, timestamp), {type: a.file!.type});
                    await FtpService.uploadFile(adaptedFile, standardizeTopicName(a.topic!));
                }
            }

            //reset data
            setData(INITIAL_DATA);
            updateFields({newExercises: [], newAttachments: []})
            setIsSaving(false);

            setModal({
                ...modal,
                show: true,
                title: "Hinweis",
                body: "Der neue Plan wurde erfolgreich nachbearbeitet!",
                decline: false,
                handleOk: () => {
                    setModal({...modal, show: false});
                    navigate('/plans', {replace: true})
                },
                handleCancel: () => {}
            })
        }
    }

    return (
        <div>
            <AppHeader showStartpageIcon={true} showNewPlanIcon={false}
                       showUserIcon={true} showHomeIcon={false} showBigLogo={false}/>
            <Container className="app-content-container">
                <form onSubmit={onSubmit}
                      onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
                          if (e.key === "Enter") {
                              e.preventDefault();
                          }
                      }}
                >
                    {step}
                    <div className="newPlanButtonRow">
                        <Button onClick={isFirstStep ? handleBackToOverview : back}>
                            {isFirstStep ? "Zurück zur Übersicht" : "Zurück"}
                        </Button>
                        <Button type="submit" disabled={isSaving}>{isLastStep ? "Speichern" : "Weiter"}</Button>
                    </div>
                </form>
                <CustomModal title={modal.title}
                             body={modal.body}
                             decline={modal.decline} showModal={modal.show} handleOk={modal.handleOk}
                             handleCancel={modal.handleCancel}
                />
            </Container>
        </div>
    )
}