import SocialMedia from "../header/SocialMedia";
import Logo from "../header/Logo";
import React from "react";
import {Link} from "react-router-dom";


export default function Home() {
    const height = 150;
    const gap = "5rem";
    const fontSize = "1.5rem";

    return(
        <div className="homePage">
            <div className="header">
                <Logo />
                <SocialMedia/>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-frauen.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{paddingTop: "3rem"}}>
                <div className="main-navigation"
                     style={{display: "flex", gap: gap, alignItems: "center", flexWrap: "wrap"}}>
                    <div className="icon-text">
                        <Link to="/tyb">
                            <img src={require('./../../assets/icons/TYBO.png')} alt="TYBO-icon"
                                 height={height}/>
                        </Link>
                        <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                            <b>TrainYourBrain</b>
                        </p>
                    </div>
                    <div className="icon-text">
                        <Link to="/login">
                            <img src={require("../../assets/icons/login.png")} alt="login-icon"
                                 height={height}/>
                        </Link>
                        <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                            <b>Login</b>
                        </p>
                    </div>
                    <div className="icon-text">
                        <Link to="/impressum">
                            <img src={require("../../assets/icons/Impressum.png")} alt="impressum-icon"
                                 height={height}/>
                        </Link>
                        <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                            <b>Impressum</b>
                        </p>
                    </div>
                    <div className="icon-text">
                        <Link to="/contact">
                            <img src={require("../../assets/icons/Team.png")} alt="team-icon"
                                 height={height}/>
                        </Link>
                        <p style={{textAlign: "center", fontSize: fontSize, margin: 0}}>
                            <b>Kontakt</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};