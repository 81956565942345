import { Link } from "react-router-dom";
import { useState } from "react";

export default function Navigation() {
    const [menuOpen, setMenuOpen] = useState(false);
    const height = 60;
    const gap = "1rem";
    const fontSize = "1rem";

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState); // Toggle menu state
    };

    return (
        <div className="navigation-container">
            {/* Burger Menu Button (Only visible on small screens) */}
            <button className="menu-button" onClick={toggleMenu}>
                ☰
            </button>

            {/* Navigation Links (Collapsible on small screens) */}
            <div className={`header-navigation ${menuOpen ? "open" : ""}`}>
                <div className="icon-text">
                    <Link to="/" onClick={() => setMenuOpen(false)}>  {/* Close menu on link click */}
                        <img src={require('./../../assets/icons/home.png')} alt="home-icon" height={height} />
                        <p style={{ textAlign: "center", fontSize: fontSize, margin: 0 }}>
                            <b>Home</b>
                        </p>
                    </Link>
                </div>
                <div className="icon-text">
                    <Link to="/tyb" onClick={() => setMenuOpen(false)}>
                        <img src={require('./../../assets/icons/TYBO.png')} alt="TYBO-icon" height={height} />
                        <p style={{ textAlign: "center", fontSize: fontSize, margin: 0 }}>
                            <b>TrainYourBrain</b>
                        </p>
                    </Link>
                </div>
                <div className="icon-text">
                    <Link to="/login" onClick={() => setMenuOpen(false)}>
                        <img src={require("../../assets/icons/login.png")} alt="login-icon" height={height} />
                        <p style={{ textAlign: "center", fontSize: fontSize, margin: 0 }}>
                            <b>Login</b>
                        </p>
                    </Link>
                </div>
                <div className="icon-text">
                    <Link to="/impressum" onClick={() => setMenuOpen(false)}>
                        <img src={require("../../assets/icons/Impressum.png")} alt="impressum-icon" height={height} />
                        <p style={{ textAlign: "center", fontSize: fontSize, margin: 0 }}>
                            <b>Impressum</b>
                        </p>
                    </Link>
                </div>
                <div className="icon-text">
                    <Link to="/contact" onClick={() => setMenuOpen(false)}>
                        <img src={require("../../assets/icons/Team.png")} alt="team-icon" height={height} />
                        <p style={{ textAlign: "center", fontSize: fontSize, margin: 0 }}>
                            <b>Kontakt</b>
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    );
}