import {Exercise, Participant} from "../../../../../types/domain";
import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";

/**
 * Helper function to export exercises in pdf
 * @param planDate date of the plan
 * @param exercises list with the exercises
 * @param name name of the group or the participant
 * @param participants list of participants if it is a single training
 * @param topic topic of the training
 * @author KMU
 */
export async function exportExerciseList(planDate: string, exercises: Array<Exercise>,
                                         name: string, participants: Array<Participant> | undefined,
                                         topic: string){
    const doc = new jsPDF({orientation: 'landscape'});
    const totalPagesExp = '{total_pages_count_string}';

    let body = [];
    //build exercise list
    for (let i in exercises){
        const attachCount = exercises[i].attachments.filter(a => a.topic.name == topic || a.topic.name == "ALLGEMEIN").length;
        body.push([exercises[i].name, exercises[i].description,
            exercises[i].methods.map(m => m.code).join(', '),
            exercises[i].material, attachCount === 1 ? attachCount + " Anhang" : attachCount + " Anhänge", ""])
    }

    //build table for pdf
    autoTable(doc, {
        head: [['Übung/Tätigkeit', 'Beschreibung', 'Methode', 'Material', 'Anhänge', 'durchgeführt']],
        body: body,
        willDrawPage: function (data) {
            // Header
            doc.setFontSize(20)
            doc.setTextColor(40)
            doc.text('Übungsliste für ' + name + ' am ' +
                new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(planDate)),
                15, 22)
        },
        didDrawPage: function (data) {
            // Footer
            var str = 'Seite ' + doc.getNumberOfPages()
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' von ' + totalPagesExp
            }
            doc.setFontSize(10)

            var pageSize = doc.internal.pageSize
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
            doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
        margin: { top: 30 }
    })

    if (participants){
        doc.addPage(undefined, "p");

        let body = [];
        //build exercise list
        for (let i in participants){
            body.push([participants[i].firstname + " " + participants[i].lastname, ""])
        }

        autoTable(doc, {
            head: [['Teilnehmer*innen', 'Unterschrift']],
            body: body,
            willDrawPage: function (data) {
                // Header
                doc.setFontSize(20)
                doc.setTextColor(40)
                doc.text('Teilnehmer*innenliste für ' + name + ' am ' +
                    new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(planDate)),
                    15, 22)
            },
            didDrawPage: function (data) {
                // Footer
                var str = 'Seite ' + doc.getNumberOfPages()
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' von ' + totalPagesExp
                }
                doc.setFontSize(10)

                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text(str, data.settings.margin.left, pageHeight - 10)
            },
            margin: { top: 30 }
        })
    }

    //set total page number
    if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages('{total_pages_count_string}')
    }

    //export pdf
    doc.save('Übungsliste_'+name+"_"+ new Date(planDate)
        .toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
        .replace(/\./g, '_') +'.pdf')
}